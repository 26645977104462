// A utility class that bridges Bootstrap/jQuery/select2 events to native DOM events
// jQuery's event system is internal and not accessible to Stimulus, so we need to use the native event API to dispatch the event
// This allows us to use something like `data-action="shown.bs.modal->controllerName#modalShown"` in Stimulus without issues
// bootstrap4 and select2 use jQuery for event binding, so we need to expose their custom events as well
export class EventBridge {
  static events() {
    // show = about to be shown
    // hide = about to be hidden
    // shown = fully shown
    // hidden = fully hidden
    const bootstrapEvents = [
      'show.bs.modal',
      'hide.bs.modal',
      'shown.bs.modal',
      'hidden.bs.modal',
      'show.bs.tab',
      'hide.bs.tab',
      'shown.bs.tab',
      'hidden.bs.tab',
      'show.bs.tooltip',
      'hide.bs.tooltip',
      'shown.bs.tooltip',
      'hidden.bs.tooltip',
      'show.bs.popover',
      'hide.bs.popover',
      'shown.bs.popover',
      'hidden.bs.popover'
    ]

    const select2Events = [
      'select2:closing',
      'select2:close',
      'select2:opening',
      'select2:open',
      'select2:selecting',
      'select2:select',
      'select2:unselecting',
      'select2:unselect',
      'select2:clearing',
      'select2:clear'
    ]

    return [...bootstrapEvents, ...select2Events]
  }

  static setupEvents() {
    this.events().forEach(event => {
      $(document).on(event, function(jqueryEvent) {
        // Skip if this event was triggered by our bridge
        if (jqueryEvent.originalEvent instanceof CustomEvent &&
            jqueryEvent.originalEvent.type === event) {
          return;
        }

        const nativeEvent = new CustomEvent(event, {
          bubbles: true,
          cancelable: true,
          detail: {
            originalEvent: jqueryEvent.originalEvent,
            jqueryEvent: jqueryEvent
          }
        })

        jqueryEvent.target.dispatchEvent(nativeEvent)
      })
    })
  }
}
