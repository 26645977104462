import { Controller } from "@hotwired/stimulus"
import { post, get } from '@rails/request.js'

export default class extends Controller {
  static values = { requirementsPath: String }
  static targets = [
    'requirements', 'clinicSelect', 'rentalProduct', 'protocolDetails', 'toggleProtocolButton',
    'diagnosesSection', 'diagnosesList', 'productsSection', 'productsList', 'productCard', 'submitButton', 'populatePhysicianName']

  connect() {}

  requirementsTargetConnected() {
    this._toggleCreateButton(this.requirementsTarget.dataset.valid === "true");
  }

  toggleProtocolButtonTargetConnected() {
    $('[data-toggle="tooltip"]').tooltip()
    this.toggleProtocolButton()
  }

  updateSidebar(event) {
    post(this.requirementsPathValue, {
      body: new FormData(this.element),
      responseKind: "turbo-stream"
    })
  }

  fetchProtocols(event) {
    const medical_professional_id = event.target.value;
    if (medical_professional_id) {
      get(`/admin/patient_agreements/fetch_protocols?medical_professional_id=${medical_professional_id}`, {
        responseKind: "turbo-stream"
      });
    } else {
      document.getElementById('protocols').innerHTML = '';
    }
  }

  handleUseProtocolClick(event) {
    const protocolsSelect = document.getElementById('protocol-select');
    if (protocolsSelect) {
      const activeProtocols = Array.from(protocolsSelect.options).filter(option => option.value !== '');
      if (activeProtocols.length === 1) {
        const protocolId = activeProtocols[0].value;
        this._selectProtocol(protocolId);
      }
    } else {
      console.error('Element with ID "protocol-select" not found.');
    }
  }

  _selectProtocol(protocolId) {
    const protocolSelect = document.getElementById('protocol-select');
    protocolSelect.value = protocolId;

    const event = new Event('change', { bubbles: true });
    protocolSelect.dispatchEvent(event);

    this.fetchDiagnoses({ target: { value: protocolId } });
    this.fetchProducts({ target: { value: protocolId } });
  }

  toggleProtocolDetails(event) {
    const protocolDetails = this.protocolDetailsTarget;
    const toggleButton = this.toggleProtocolButtonTarget;

    if (protocolDetails.classList.contains('d-none')) {
      protocolDetails.classList.remove('d-none');
      toggleButton.textContent = 'Cancel Protocol';
    } else {
      protocolDetails.classList.add('d-none');
      toggleButton.textContent = 'Use Protocol';
      this.resetProtocolForm();
    }
  }


  addPopulatePhysicianNameField() {
    const populatePhysicianName = document.getElementById('populate-physician-name-field-tag');

    if (populatePhysicianName) {
      populatePhysicianName.value = true;
    }
  }

  toggleProtocolButton(event) {
    if ($('#protocols').find('.panel').length) {
      const toggleButton = this.toggleProtocolButtonTarget;
      //If Clinic is set, enable the use protocol button
      if (this.clinicSelectTarget.value) {
        $(toggleButton).tooltip('dispose');
        $(toggleButton).prop('disabled', false);
        const protocolId = document.getElementById('protocol-select').value;
        //If protocol is selected, fetch diagnoses/products (in the event the clinic is changed after setting protocol)
        if (protocolId) {
          this.fetchDiagnoses({ target: { value: protocolId } });
          this.fetchProducts({ target: { value: protocolId } });
        }
      } else {
        if (toggleButton.textContent == 'Cancel Protocol') {
          //Making sure to clear out the protocol section if clinic gets cleared
          $(this.toggleProtocolButtonTarget).trigger('click');
        }
        $(toggleButton).prop('disabled', true);
        $(toggleButton).attr('title', 'Select a Clinic Location before clicking Use Protocol');
        $(toggleButton).tooltip();
      }
    }
  }

  _toggleCreateButton(formComplete) {
    if (formComplete) {
      $('.submit-button').prop('disabled', false);
    } else {
      $('.submit-button').prop('disabled', true);
    }
  }

  resetProtocolForm() {
    $('#protocol-select').val('').trigger('change');
    this.diagnosesSectionTarget.classList.add('d-none');
    this.diagnosesListTarget.innerHTML = '';
    this.productsSectionTarget.classList.add('d-none');
    this.productsListTarget.innerHTML = '';
  }

  fetchDiagnoses(event) {
    const protocolId = event.target.value;
    if (protocolId) {
      get(`/admin/patient_agreements/fetch_diagnoses?protocol_id=${protocolId}`, {
        responseKind: "turbo-stream"
      }).then(() => {
        this.diagnosesSectionTarget.classList.remove('d-none');
      });
    } else {
      this.diagnosesSectionTarget.classList.add('d-none');
      this.diagnosesListTarget.innerHTML = '';
    }
  }

  fetchProducts(event) {
    const clinicId = $('#patient_agreement_clinic_id').val()
    const protocolId = event.target.value;
    if (protocolId) {
      get(`/admin/patient_agreements/fetch_products?protocol_id=${protocolId}&clinic_id=${clinicId}`, {
        responseKind: "turbo-stream"
      }).then(() => {
        this.productsSectionTarget.classList.remove('d-none');
      });
    } else {
      this.productsSectionTarget.classList.add('d-none');
      this.productsListTarget.innerHTML = '';
    }
  }

  toggleSelected(event) {
    const checkbox = event.target;
    const card = checkbox.closest(".card");

    if (checkbox.checked) {
      card.classList.add("selected");
    } else {
      card.classList.remove("selected");
    }
  }

  removeRentalProduct(event) {
    let msg = event.target.dataset.confirm
    this.rentalProductTarget.remove()
    history.replaceState({}, '', location.pathname)
  }

  submitForm(event) {
    event.preventDefault();
    const form = this.element;
    const checkboxes = form.querySelectorAll('.inventory-item-checkbox');

    checkboxes.forEach(checkbox => {
      if (!checkbox.checked) {
        const productCard = checkbox.closest('.card');
        const select = productCard.querySelector('select');
        if (select) {
          select.disabled = true;
        }
      }
    });

    form.submit();
  }
}
