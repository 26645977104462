import { get, patch, post } from "@rails/request.js"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'productSelectionModal',
    'productCard',
    'addItem',
    'inventoryItems',
    'expand',
    'collapse',
    'itemSelectionExpanded',
    'submitButton',
    'modalProductCard'
  ]

  connect() {
    this.patientAgreementID = $('.page-header').data('id')
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').content
    this.productType = $(this.productCardTarget).data('product-type')
    if (this.productType == 'custom') {
      this.addCustom()
    } else {
      this.autoAddItems()
    }
  }

  resetAddForm() {
    $('.inventory-quantity').val(1)
  }

  updateSelectedCount() {
    let selectedCount = $('.inventory-items-list form').length + 1
    $('.selected-count__count').html(selectedCount)
    $('.selected-count').removeClass('d-none')
    $('#no-items-selected-warning').addClass('d-none')
  }

  updatePage() {
    if ($('.products-panel').find('.panel__body .card').length) {
      $('#products-search-section')[0].scrollIntoView({block: 'center', behavior: 'smooth'})
    }
  }

  validateForm(form) {
    let isValid = true;

    const $fulfillFrom = $(form).find('[data-pa-product-selection-target="fulfillFrom"]');
    const $billingChannel = $(form).find('[data-pa-product-selection-target="billingChannel"]');
    const $inventoryLocation = $(form).find('[data-pa-product-selection-target="inventoryLocation"]');
    const $rentalPeriod = $(form).find('[data-pa-product-selection-target="rentalPeriod"]');
    const $orientations = $(form).find('[data-pa-product-selection-target="orientation"]');
    const $orientationChecked = $(form).find("input[data-pa-product-selection-target='orientation']:checked").val()

    const validateInput = ($input) => {
      if ($input.length) {
        if (!$input.val()) {
          $input.parents(".col-form").addClass("error");
          isValid = false;
        }
        if ($.isNumeric($input.val())) {
          if ($input.val() < 0 || $input.val() == 0) {
            $input.parents(".col-form").addClass("error").addClass("error--negative");
            isValid = false;
          }
        }
      }
    };

    validateInput($fulfillFrom);
    validateInput($billingChannel);
    validateInput($inventoryLocation);
    validateInput($rentalPeriod);

    if (!$orientationChecked) {
      $orientations.parents(".col-form").addClass("error");
      isValid = false;
    } else {
      $orientations.parents(".col-form").removeClass("error");
    }

    return isValid;
  }

  async refreshProducts() {
    const ajaxCall = await get(`/admin/patient_agreements/patient_agreement_products?patient_agreement_id=${this.patientAgreementID}`, {
      responseKind: "turbo-stream"
    }).then((response) => this.updatePage())
    .then(() => BigSpinner.finished())
  }

  async addCustom() {
    $(this.productSelectionModalTarget).addClass('custom-product')
    $('.item-selection').addClass('d-none')
    $('#custom-selected-product-card').removeClass('d-none')
    BigSpinner.add()
    const ajaxCall = await get(`${this.productCardTarget.dataset.url}&patient_agreement_id=${this.patientAgreementID}&qty=1&sku=0`, {
      responseKind: "turbo-stream"
    }).then((response) => this.resetAddForm())
    .then(() => BigSpinner.finished())
  }

  async addItems(event) {
    this.disableRentalProduct(event.target.dataset.sku)
    let qty = 1
    if ($(event.target).parents('.inventory_item').find('.inventory-quantity').length) {
      qty = $(event.target).parents('.inventory_item').find('.inventory-quantity').val()
    }
    let productParam = `${this.productType === 'rental' ? 'serial_number' : 'inventory_item_id'}=${event.target.dataset.sku}`
    let url = `${event.target.dataset.url}&patient_agreement_id=${this.patientAgreementID}&qty=${qty}&type=${this.productType}&${productParam}`

    BigSpinner.add()
    const ajaxCall = await get(url, {
      responseKind: "turbo-stream"
    }).then((response) => this.resetAddForm())
    .then(() => this.collapseItems())
    .then(() => BigSpinner.finished())
  }

  async submitForm(event) {
    const forms = this.element.querySelectorAll('.edit-patient-agreement-product-form');
    if(!forms[0]) { return; }
    let url = forms[0].getAttribute('action');

    this.submitButtonTargets.forEach(button => {
      button.classList.add('disabled')
    })

    let allFormsValid = true;
    let params = new FormData();

    forms.forEach((form) => {
      if (this.validateForm(form)) {
        let formData = new FormData(form);
        for (const pair of formData.entries()) {
          let [key, val] = pair;
          params.append(key, val);
        }
      } else {
        allFormsValid = false;
      }
    });

    if (allFormsValid) {
      if(event.target.dataset.submittingForm) {
        // do action
      } else {
        await post(url, {
          responseKind: "turbo-stream",
          body: params
        });
      }
    } else {
      this.submitButtonTargets.forEach(button => {
        button.classList.remove('disabled')
      })

      event.preventDefault()
    }
  }

  expandItems() {
    $(this.expandTarget).addClass('d-none')
    $(this.collapseTarget).removeClass('d-none')
    $(this.itemSelectionExpandedTarget).removeClass('d-none')
  }

  collapseItems() {
    $(this.expandTarget).removeClass('d-none')
    $(this.collapseTarget).addClass('d-none')
    $(this.itemSelectionExpandedTarget).addClass('d-none')
  }

  autoAddItems() {
    if (this.addItemTargets.length == 1) {
      this.addItemTarget.click()
    }
  }
  
  disableRentalProduct(serial_number) {
    if (this.productType != 'rental') return

    $(`.rental-product-${serial_number}`).addClass('d-none')
  }

  modalProductCardTargetConnected() {
    this.submitButtonTargets.forEach(button => {
      button.classList.remove("disabled")
    });
  }

  disableSubmitBtn(event) {
    // logically this should be, "if no cards exist then disable" but because this won't fire
    // if the element ddoesn't exist, i had to switch the logic so this calls before the element
    // is removed. -TB
    if(this.modalProductCardTargets.length == 1 && this.modalProductCardTarget == $(event.target).closest("fieldset")[0]) {
      this.submitButtonTargets.forEach(button => {
        button.classList.add("disabled")
      });
    }
  }
}
