// This class is a base class for all modal controllers and should not be used directly in the DOM.
// The buttons that open the modal should be hidden in the DOM and shown by implementing `showOpenModalButtons`

// This class is used to control the Singleton modal present in the DOM. It also provides some baseline functionality for modals.
// In general, it should not be used as a base class to extend from.
// Opening modals is handled in the `modal_links_controller.js` controller.
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["success", "content"]

  connect() {
    this.setupEventListeners()
  }

  setupEventListeners() {
    this.element.addEventListener('shown.bs.modal', () => this.modalShown())
    this.element.addEventListener('hidden.bs.modal', () => this.modalHidden())
  }

  modalShown() {
    // Implement callback in your controller if needed
    // add .autofocus to form element to autofocus after shown.bs.modal
    const $focusElement = $('#turbo-modal-content').find('.autofocus').first()
    if ($focusElement.length) {
      this.autoFocus($focusElement)
    }
  }

  modalHidden() {
    // Implement callback in your controller if needed
  }

  showModal() {
    $(this.element).modal("show")
  }

  hideModal() {
    $(this.element).modal("hide")
  }

  autoFocus($focusElement) {
    // Best cross-browser way to focus while handling the case of putting the cursor after an existing value and not at the start
    $focusElement.trigger('focus')
    let tmpStr = $focusElement.val()
    $focusElement.val('')
    $focusElement.val(tmpStr)
    $focusElement.attr('data-original-note', tmpStr)
  }

  successTargetConnected() {
    $(this.element).modal("hide")
  }

  contentTargetConnected() {
    BigSpinner.finished()
    $(this.element).modal("show")
  }
}
